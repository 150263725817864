
.noDisplay {
    display: none !important;
  }
  
  .pdfHeader {
    margin-top: 15px;
    margin-left: 10px;
    margin-bottom: 15px;
  }
  