.home {
  color: #fff;
}

.textFields {
  margin: 12px 0 !important;
}

.leftTextField {
  margin-right: 5px !important;
  width: 95% !important;
}

.rightTextField {
  margin-left: 5px !important;
  width: 95% !important;
}

.error {
  color: red !important;
  margin: 0 !important;
  margin-top: -8px !important;
}

.groupTextfield {
  display: flex;
}

.groupTextfield .textFields {
  margin: 10px 5px !important;
}

.autoCompleteField {
  width: 100%;
  margin: 10px 5px !important;
}

.tabListBody > div {
  padding-top: 0 !important;
}

.checkDetailsHeader {
  display: flex;
  justify-content: right;
  align-items: baseline;
}

.checkDetailsHeader h3 {
  margin: 0;
}

.groupButtonTab {
  display: flex;
  align-items: end;
}

.formBody {
  height: 200px !important;
  border: dashed !important;
  display: grid;
  margin-bottom: 20px;
}

form .input {
  position: relative;
  opacity: 0;
  z-index: 99;
  height: 200px;
}

.actionButtonHoverable {
  position: absolute;
  min-width: 65px;
  right: -7px !important;
  margin-top: 5px !important;
  border-bottom: none !important;
  opacity: 0;
  z-index: 10;
}

.actionButtonHoverable td {
  border-bottom: none !important;
}

.actionButtonHoverable:hover {
  opacity: 1;
}

.tableBody tr:hover + .actionButtonHoverable {
  opacity: 1;
  transition: opacity 1s;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
}

.actionButton button {
  margin: 5px;
  border-radius: 20px !important;
}

.addressBody {
  display: flex;
  width: 50%;
}

.addressBody div:first-child {
  width: 120px;
  font-weight: bold;
  font-size: 16px;
  margin-top: 15px;
  margin-left: 10px;
}

.referenceNumber {
  width: 150px !important;
}

.addressBody div:nth-child(2) {
  font-size: 16px;
  margin-top: 15px;
}

.tabLabel {
  font-weight: 600;
}
