.home {
  color: #fff;
}

.textFields {
  margin: 12px 0 !important;
}

.customFieldsForInstallment {
  margin: 12px 0 !important;
}

.customFieldsForInstallment input {
  text-align: right !important;
}

.disableInput {
  pointer-events: none !important;
  margin: 12px 0 !important;
}

.disableInput input {
  text-align: right !important;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
}

.error {
  color: red !important;
  margin: 0 !important;
  margin-top: -8px !important;
}

.actionButton button {
  border-radius: 20px !important;
  margin: 5px;
}

.flex {
  display: flex;
}

.groupFields {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.groupFields .textFields {
  width: 274px !important;
}

.groupFieldsRegister {
  display: flex;
  justify-content: space-between;
}

.groupFieldsRegister div {
  width: 270px;
}

.RegistryField {
  width: 270px !important;
  margin: 12px 0 !important;
}

.autocompleteFiled {
  width: 270px !important;
  margin: 12px 0 !important;
}

.CustomShortTitle {
  width: 250px !important;
  margin: 12px 0 !important;
}

.addressBody {
  display: flex;
}

.tabListBody > div {
  padding-top: 0 !important;
}

.addressBody div:first-child {
  width: 120px;
  font-weight: bold;
  font-size: 16px;
}

.headerContent {
  display: flex;
  justify-content: space-between;
  font-size: 19px;
  align-items: center;
  background: cadetblue;
  color: aliceblue;
  font-weight: 600;
  padding: 0 10px;
  margin-top: 10px;
}

.termsBody {
  display: flex;
}

.textFieldsAlignRight input {
  text-align: right !important;
}

.detailListCustom {
  display: flex;
  font-size: 16px;
  text-transform: capitalize;
}

.detailListCustom .wordAnd {
  text-transform: lowercase !important;
}

.detailListCustom div:first-child {
  min-width: 255px;
}

.valueDetails {
  display: flex;
}

.groupButtonShow {
  display: flex;
  align-items: flex-start;
}

.showTitleHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detailsBody {
  display: flex;
  font-size: 16px;
}

.leftShowDetails {
  width: 50%;
  margin-right: 10px;
}

.rightShowDetails {
  width: 50%;
}

.showTitleHeader h2 {
  margin: 0;
}

.detailList {
  display: flex;
  font-size: 16px;
}

.detailList div:first-child {
  min-width: 255px;
}

.checkDetailsHeader h3 {
  margin: 0 !important;
}

.checkDetailsHeader {
  display: flex;
  justify-content: right;
  align-items: baseline;
}

.actionButtonHoverable {
  position: absolute;
  min-width: 65px;
  right: 40px !important;
  margin-top: 0 !important;
  border-bottom: none !important;
  opacity: 0;
  z-index: 10;
}

.actionButtonHoverable td {
  border-bottom: none !important;
}

.actionButtonHoverable:hover {
  opacity: 1;
}

.groupAddClient button {
  padding-top: 0 !important;
}

.tableBodyNew .actionButtonHoverable {
  position: absolute;
  min-width: 65px;
  right: -16px !important;
  margin-top: 0 !important;
  border-bottom: none !important;
  opacity: 0;
  z-index: 10;
  padding: 0;
}

.tableBody:hover .actionButtonHoverable {
  opacity: 1;
  transition: opacity 1s;
}

.tableBodyNew .actionButtonHoverable button {
  padding: 5px;
}

.tableBodyNew .actionButtonHoverable td {
  border-bottom: none !important;
}

.tableBodyNew .actionButtonHoverable:hover {
  opacity: 1;
}

.tableBodyNew:hover .actionButtonHoverable {
  opacity: 1;
  transition: opacity 1s;
}

.noBorder {
  border: none;
}

.tableRowBody:hover {
  cursor: pointer;
}

.groupAddClient {
  display: flex;
}

.customAddress {
  display: flex;
}

.customAddress div:first-child {
  min-width: 12px;
}

.textFieldsInstallment {
  margin: 12px 0 !important;
}

.textFieldsInstallment input {
  text-align: right !important;
}

.journalBody {
  display: flex;
  justify-content: space-between;
}

.journalList {
  display: flex;
}

.journalList div:first-child {
  min-width: 250px;
}

.actionJournalBody {
  display: flex;
  align-items: center;
}

.refInput {
  margin-right: 0 !important;
}

.switchLabel span:last-child {
  font-size: 20px;
  font-weight: 600;
}

.isCheckPaymentBody {
  border: 1px solid #c0c0c0;
  padding: 5px !important;
  margin: 5px !important;
}

.actionAttactmentButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 70px !important;
}

.noDisplay {
  display: none !important;
}

.tableBodyPrint {
  margin: 0 10px;
}

.tabLabel {
  font-weight: 700 !important;
}

.labelDisplay h2 {
  margin: 0 !important;
}

@media print {
  footer {
    position: fixed;
    bottom: 30px;
    right: 20px;
  }
  
  footer h3 {
    text-align: center;
    margin: 3px;
    font-style: italic;
    font-weight: 500;
  }

  .tabListBody {
    padding: 0 !important;
  }

  .tableHead tr th {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
}

.signLine {
  border: 1px solid #888888 !important;
  width: 200px !important;
}
