.home {
  color: #fff;
}

.textFields {
  margin: 12px 0 !important;
}

.leftTextField {
  margin-right: 5px !important;
  width: 95% !important;
}

.rightTextField {
  margin-left: 5px !important;
  width: 95% !important;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
}

.actionButton a {
  text-decoration: none !important;
}

.error {
  color: red !important;
  margin: 0 !important;
  margin-top: -8px !important;
}

.actionButton button {
  margin: 5px;
  border-radius: 20px !important;
}

.flex {
  display: flex;
}

.detailList {
  display: flex;
  margin: 5px;
}

.tabListBody > div {
  padding-top: 0 !important;
}

.detailList div:first-child {
  width: 300px;
}

.title {
  flex-grow: 1;
  font-weight: initial;
  margin-left: 10px;
  text-transform: capitalize;
}

.emailBody {
  display: flex;
  align-items: flex-start;
}

.formBody {
  display: flex;
  justify-content: space-between;
}

.formAttachBody {
  height: 200px !important;
  border: dashed !important;
  display: grid;
  margin-bottom: 20px;
}

form .input {
  position: relative;
  opacity: 0;
  z-index: 99;
  height: 200px;
}

.fileError {
  color: red;
}

form .formAttachBody .filelabel {
  text-align: center;
  margin-top: -109px;
}

form .formTextfield {
  width: 290px;
}

.actionAttactmentButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 140px !important;
}

.detailsBody {
  display: flex;
  font-size: 16px;
}

.leftShowDetails {
  width: 50%;
  margin-right: 10px;
}

.rightShowDetails {
  width: 50%;
}

.showTitleHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.showTitleHeader h2 {
  margin: 0;
}

.checkDetailsHeader {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.checkDetailsHeader h3 {
  margin: 0;
}

.headerBody {
  display: flex;
  align-items: baseline;
}

.contactLists {
  display: flex;
  align-items: flex-start;
  margin-left: 26px !important;
}

.parentTitle {
  display: flex;
  align-items: center;
}

.parentTitle svg {
  margin-right: 5px !important;
}

.contactListBody {
  display: flex;
  align-items: center;
  position: relative;
}

.actionButtonHoverable {
  position: absolute;
  min-width: 65px;
  margin-top: -5px !important;
  border-bottom: none !important;
  opacity: 0;
  right: 170px;
  z-index: 10;
}

.contactListBody:hover .actionButtonHoverable {
  opacity: 1;
  transition: opacity 1s;
}

.nestedBody {
  margin-top: -25px !important;
}

.contactHeader {
  display: flex;
}

.contactTableBody {
  position: relative;
}

.buttonsHover {
  position: absolute;
  min-width: 65px;
  margin-top: 5px !important;
  border-bottom: none !important;
  opacity: 0;
  right: 5px;
  z-index: 10;
}

.contactPersonRow:hover .buttonsHover {
  opacity: 1;
  transition: opacity 1s;
}

.contactPersonRow {
  vertical-align: top !important;
}

.groupButtonShow {
  display: flex;
  align-items: flex-start;
}

th.customCellValue th {
  border: none !important;
  width: 325px;
}

td.customCellValue tr td {
  border: none !important;
  border-bottom: none !important;
  width: 325px;
}

.tabLabel {
  font-weight: 600;
}
