.home {
  color: #fff;
}

.textFields {
  margin: 12px 0 !important;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
}

.error {
  color: red !important;
  margin: 0 !important;
  margin-top: -8px !important;
}

.actionButton button {
  border-radius: 20px !important;
  margin: 5px;
}

.flex {
  display: flex;
}

.groupFields {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.groupFields .textFields {
  width: 274px !important;
}

.groupFieldsRegister {
  display: flex;
  justify-content: space-between;
}

.groupFieldsRegister div {
  width: 270px;
}

.RegistryField {
  width: 270px !important;
  margin: 12px 0 !important;
}

.autocompleteFiled {
  width: 270px !important;
  margin: 12px 0 !important;
}

.CustomShortTitle {
  width: 250px !important;
  margin: 12px 0 !important;
}

.codeTextField {
  margin: 12px 0 !important;
}

.codeTextField input {
  text-align: right !important;
}

.refInput {
  margin-left: 0 !important;
}
