.actionButton button {
  border-radius: 20px !important;
  margin: 5px;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
}

.clientName {
  width: 530px !important;
}

.ref {
  width: 150px;
}

.tableBody tr {
  vertical-align: top !important;
}

.customCell th {
  border: none !important;
}

.customCell th.ref {
  width: 150px !important;
  padding-left: 0 !important;
}

.customCell th.dateInvoice {
  width: 120px !important;
  padding-left: 0 !important;
}

.customCell th.dateDue {
  width: 150px !important;
  padding-left: 0 !important;
}

.customCell th.amountTotal {
  width: 130px !important;
  padding-left: 0 !important;
}

.tableBody .amountTotal {
  width: 130px;
  text-align: right !important;
}

.customCell th.amountPayment {
  width: 130px !important;
}

.tableBody .amountPayment {
  width: 130px;
  text-align: right !important;
}

.customCell th.amountBalance {
  width: 130px !important;
}

.tableBody .amountBalance {
  width: 130px;
  text-align: right !important;
}

.dateInvoice {
  width: 120px;
}

.dateDue {
  width: 150px;
}

.grandTotal {
  width: 120px !important;
  text-align: right !important;
  font-weight: 800 !important;
  font-style: italic !important;
  color: red;
}

.textAlignRight {
  text-align: right !important;
}

.grandTotalValue {
  text-align: right !important;
  border-bottom: double;
  margin: 0 10px !important;
}

.tileBody {
  display: flex;
  align-items: center;
}

.tileBody svg {
  margin-right: 10px;
}

.tableBody .linkable:hover {
  cursor: pointer;
}

.headerBody {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.buttonDisbursement {
  width: 60px;
  display: flex;
  justify-content: space-between;
}

.noDisplay {
  display: none !important;
}

.tableBodyHeight {
  margin: 0 10px;
}
