.actionButton button {
  border-radius: 20px !important;
  margin: 5px;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
}

.cellNone {
  width: 53%;
}

.grandTotal {
  width: 30% !important;
  text-align: right !important;
  font-weight: 800 !important;
  font-style: italic !important;
  color: red;
}

.grandDebit {
  width: 13%;
  font-weight: 800 !important;
  border-bottom: double !important;
}

.grandCredit {
  width: 10.8%;
  font-weight: 800 !important;
  border-bottom: double !important;
}

.grandTotalTable {
  padding-right: 21px !important;
  border-bottom: double !important;
  font-weight: 800 !important;
}